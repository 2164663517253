<template>
  <div
    class="examCBT"
    v-if="dataQuestionCBTStudent.mata_pelajaran || isTeacher"
  >
    <!-- {{jawabanAPI}} -->
    <!-- {{dataQuestionCBTStudent}} -->
    <!-- {{totalQuestion}} -->
    <div v-if="!isTeacher" class="countdownTimer">
      <h4>TIME LEFT</h4>
      <h2>{{ formattedDuration }}</h2>
    </div>
    <a-modal
      :visible="modalSubmit"
      @cancel="cancelModal"
      @ok="submitAnswer"
      ok-text="Submit"
      :cancelButtonProps="{
        props: {
          type: 'danger',
        },
      }"
      cancel-text="No, I still want to re-check my answer"
      :confirm-loading="loadingSubmit"
    >
      <h3 slot="title" style="line-height: 1.7rem">
        Are you sure to submit your answer?
      </h3>
      <div>
        <div>Your answer <b>could not be changed</b> once you submit.</div>
        <ul>
          <li>
            Answered: <b>{{ stats.answered }}</b>
          </li>
          <li class="text-danger">
            Not Answered: {{ totalQuestion.length - stats.answered }}
          </li>
        </ul>
      </div>
    </a-modal>
    <div class="descStartExam">
      <a-row :gutter="16">
        <a-col :span="24">
          <template v-if="!isTeacher">
            <h2 class="mb-5">
              Subject: {{ dataQuestionCBTStudent.mata_pelajaran.nama }}
            </h2>
          </template>
          <template v-else>
            <h2>QUESTION PREVIEW</h2>
            <a-button class="mb-5" @click.prevent="backDetail"
              >Back to Detail CBT</a-button
            >
          </template>
        </a-col>
      </a-row>
    </div>
    <div class="allExamNumber">
      <div class="examNumber" :key="data.no" v-for="data in totalQuestion">
        <a-button
          @click.prevent="changeCurrentNumber(data.no)"
          :style="{
            backgroundColor: colorToggle(data).backgroundColor,
            color: colorToggle(data).color,
          }"
        >
          {{ data.no }}
        </a-button>
      </div>
    </div>
    <template v-for="(data, i) in totalQuestion">
      <div
        class="soalCBT"
        :key="data.no"
        v-if="currentNumber === data.no && data"
      >
        <!-- {{data}} -->
        <SoalExamCBT
          :dataSoal="data"
          :no="data.no"
          :isTeacher="isTeacher"
          @goToNext="(payload) => updateJawaban(payload, i)"
        />
      </div>
    </template>
    <div class="d-md-flex justify-content-center">
      <div class="mb-3 mb-md-0 mr-md-3 text-center">
        <a-button
          type="primary"
          :disabled="currentNumber === 1"
          @click.prevent="clickPrevious"
          >&lt; Previous Question</a-button
        >
      </div>
      <div class="mb-3 mb-md-0 mr-md-3 text-center">
        <a-button
          class="notSureBtn"
          :disabled="
            totalQuestion[currentNumber - 1] &&
            !totalQuestion[currentNumber - 1].jawaban
          "
          @click.prevent="changeStatusUnsure"
          style="background-color: #ff8811; color: white"
          >Not Sure Yet</a-button
        >
      </div>
      <div class="text-center">
        <a-button
          v-if="currentNumber !== totalQuestion.length"
          type="primary"
          @click.prevent="clickNext"
          >Next Question &gt;</a-button
        >
        <a-button
          v-else
          :disabled="isTeacher"
          :style="{
            backgroundColor: isTeacher ? '' : '#1EBC61',
            color: isTeacher ? '' : '#ffffff',
          }"
          @click.prevent="modalSubmit = true"
          >Submit Your Answer</a-button
        >
      </div>
    </div>
    <!-- <a-row class="buttonCBT">
      <a-col :span="6" />
      <a-col :span="4" style="display:flex; justify-content: center;">
        <a-button type="primary" :disabled="currentNumber === 1" @click.prevent="clickPrevious">&lt; Previous Question</a-button>
      </a-col>
      <a-col :span="4" style="display:flex; justify-content: center;">
        <a-button class="notSureBtn" :disabled="totalQuestion[currentNumber-1] && !totalQuestion[currentNumber-1].jawaban" @click.prevent="changeStatusUnsure" style="background-color: #FF8811; color: white;">Not Sure Yet</a-button>
      </a-col>
      <a-col :span="4" style="display:flex; justify-content: center;">
        <a-button v-if="currentNumber !== totalQuestion.length" type="primary" @click.prevent="clickNext">Next Question &gt;</a-button>
        <a-button v-else :disabled="isTeacher" :style="{backgroundColor: isTeacher ? '' : '#1EBC61', color: isTeacher ? '' : '#ffffff'}" @click.prevent="modalSubmit = true">Submit Your Answer</a-button>
      </a-col>
      <a-col :span="6" />
    </a-row> -->
    <div>
      <a-divider orientation="left"><h2>Status</h2></a-divider>
      <a-row :gutter="[16, 16]" class="statusQuestion">
        <a-col :xs="24" :lg="8">
          <a-card class="cardOverall">
            <span style="color: #41b883" slot="title">Answered</span>
            <div class="centeringBody">
              <div style="color: #41b883" class="scoreStatus">
                {{ stats.answered }}
              </div>
              <div class="additionalText">question</div>
            </div>
          </a-card>
        </a-col>
        <a-col :xs="24" :lg="8">
          <a-card class="cardOverall">
            <span style="color: #ff8811" slot="title">Not Sure</span>
            <div class="centeringBody">
              <div style="color: #ff8811" class="scoreStatus">
                {{ stats.notSureYet }}
              </div>
              <div class="additionalText">question</div>
            </div>
          </a-card>
        </a-col>
        <a-col :xs="24" :lg="8">
          <a-card class="cardOverall">
            <span style="color: #ff0000" slot="title">Not Answered</span>
            <div class="centeringBody">
              <div style="color: #ff0000" class="scoreStatus">
                {{ totalQuestion.length - stats.answered }}
              </div>
              <div class="additionalText">question</div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import SoalExamCBT from './SoalExamCBT'
import moment from 'moment'

export default {
  components: {
    SoalExamCBT,
  },
  props: ['isTeacher', 'questions'],
  data() {
    return {
      currentNumber: 1,
      modalSubmit: false,
      loadingSubmit: false,
      stats: {
        answered: 0,
        notSureYet: 0,
      },
      loadingAnswer: true,
      duration: 0,
      formattedDuration: '00:00:00',
      jawabanAPI: [],
      totalQuestion: [],
    }
  },
  methods: {
    cancelModal() {
      this.modalSubmit = false
      this.$store.dispatch('cbt/UPDATE_JAWABAN_MURID_CBT_EXAM', {
        idCbtAttendance: this.dataQuestionCBTStudent.cbt_attendances[0].id,
        jawaban: this.jawabanAPI,
      })
        .then(_ => {
          location.reload()
        })
    },
    backDetail() {
      this.$emit('back-detail')
    },
    clickPrevious() {
      if (this.totalQuestion[this.currentNumber - 1].status === 'undone') {
        this.totalQuestion[this.currentNumber - 1].status = 'checked'
      }
      return this.currentNumber !== 1 ? this.currentNumber-- : ''
    },
    clickNext() {
      if (this.totalQuestion[this.currentNumber - 1].status === 'undone') {
        this.totalQuestion[this.currentNumber - 1].status = 'checked'
      }
      return this.currentNumber !== this.totalQuestion.length ? this.currentNumber++ : ''
    },
    countdownTimer() {
      if (!this.isTeacher) {
        // const now = moment('12:20', 'HH:mm')
        // const endTime = moment('12:21', 'HH:mm')
        const now = moment()
        // const durasi = localStorage.duration || this.dataQuestionCBTStudent.durasi * 60 // seconds
        const endTimeExpected = moment(this.dataQuestionCBTStudent.cbt_attendances[0].start_time).add(this.dataQuestionCBTStudent.durasi, 'minutes')
        // console.log(endTimeExpected)
        const endTime = moment().add(this.dataQuestionCBTStudent.durasi, 'minutes').isAfter(moment(this.dataQuestionCBTStudent.waktu_ujian_ditutup)) ? moment(this.dataQuestionCBTStudent.waktu_ujian_ditutup) : endTimeExpected
        const diffTime = endTime - now
        this.duration = moment.duration(diffTime, 'milliseconds')
        const timerInterval = setInterval(() => {
          if (this.duration <= 0) {
            // localStorage.removeItem('duration')
            clearInterval(timerInterval)
            this.$store.dispatch('cbt/SUBMIT_ANSWER', {
              idCbtAttendance: this.dataQuestionCBTStudent.cbt_attendances[0].id,
            })
            this.$store.commit('cbt/SET_STATE', {
              isOnExam: false,
            })
            localStorage.setItem('isOnExam', false)
            this.$notification.success({
              message: 'Success',
              description:
                'Your time is up. Your answer has been submitted. Good work!',
            })
            this.$router.push({ name: 'Cbt Student' })
          } else {
            if (this.formattedDuration === '00:30:00' || this.formattedDuration === '00:15:00' || this.formattedDuration === '00:05:00' || this.formattedDuration === '00:01:00') {
              this.$notification.warning({
                message: 'Exam Time Warning.',
                description: `Your time is ${this.duration.minutes()} minutes left.`,
              })
            } else if (this.formattedDuration === '01:00:00') {
              this.$notification.warning({
                message: 'Exam Time Warning.',
                description: 'Your time is 1 hours left.',
              })
            }
            this.duration = moment.duration(this.duration - 1000, 'milliseconds')
            this.formattedDuration = `${this.duration.hours() >= 10 ? '' : '0'}${this.duration.hours()}:${this.duration.minutes() >= 10 ? '' : '0'}${this.duration.minutes()}:${this.duration.seconds() >= 10 ? '' : '0'}${this.duration.seconds()}`
          }
        }, 1000)
      }
    },
    addStats() {
      if (!this.isTeacher) {
        let answered = 0
        let notSureYet = 0
        if (this.totalQuestion.length) {
          this.totalQuestion.forEach(question => {
            if (question.jawaban) {
              answered++
            }
            if (question.isNotSure) {
              notSureYet++
            }
          })
        }

        this.stats = {
          answered,
          notSureYet,
        }
      }
    },
    changeCurrentNumber(no) {
      if (!this.totalQuestion[this.currentNumber - 1].jawaban) {
        this.totalQuestion[this.currentNumber - 1].status = 'checked'
      }
      this.currentNumber = no
    },
    colorToggle(data) {
      if (data.no === this.currentNumber) {
        return {
          backgroundColor: '#096dd9',
          color: '#ffffff',
        }
      } else {
        if (data.isNotSure) {
          return {
            backgroundColor: '#FF8811',
            color: '#ffffff',
          }
        } else {
          if (data.status === 'answered' && !this.isTeacher) {
            return {
              backgroundColor: '#1EBC61',
              color: '#ffffff',
            }
          } else if (data.status === 'checked' && !this.isTeacher) {
            return {
              backgroundColor: '#707788',
              color: '#ffffff',
            }
          } else {
            return {
              backgroundColor: '#F0F2F4',
              color: '',
            }
          }
        }
      }
    },
    updateJawaban(payload, index) {
      if (!this.isTeacher) {
        const totalQuestionOld = []
        for (let i = 0; i < this.totalQuestion.length; i++) {
          const question = this.totalQuestion[i]
          totalQuestionOld.push({ ...question })
        }
        this.totalQuestion[index].isNotSure = false
        if (payload.jawaban === '' || !payload.jawaban) {
          this.totalQuestion[index].jawaban = null
          this.totalQuestion[index].status = 'undone'
          this.updateJawabanAPI()
          this.addStats()
        } else {
          this.totalQuestion[index].jawaban = payload.jawaban
          this.totalQuestion[index].status = 'answered'
          this.updateJawabanAPI()
          this.addStats()
        }

        // console.log(totalQuestionOld[index], this.totalQuestion[index])

        if (this.totalQuestion[index].tipeSoal === 'Multiple Choice') {
          this.loadingAnswer = true
          this.$store.dispatch('cbt/UPDATE_JAWABAN_MURID_CBT_EXAM', {
            idCbtAttendance: this.dataQuestionCBTStudent.cbt_attendances[0].id,
            jawaban: this.jawabanAPI,
          })
            .then(isSuccess => {
              if (isSuccess) {
                this.loadingAnswer = false
                this.$notification.success({
                  message: 'Updated',
                  description:
                    'Your answer has been updated',
                })
                if (this.currentNumber !== this.totalQuestion.length && this.totalQuestion[index].tipeSoal === 'Multiple Choice') {
                  this.currentNumber++
                }
              } else {
                // console.log()
                this.totalQuestion = totalQuestionOld
                this.updateJawabanAPI()
                this.addStats()
                this.$notification.error({
                  message: 'Error',
                  description:
                    'Internal server error. Please check your connection.',
                })
              }
            })
        }
      }
    },
    changeStatusUnsure() {
      this.totalQuestion[this.currentNumber - 1].isNotSure = true
      // this.totalQuestion[this.currentNumber - 1].jawaban = null
      this.addStats()
      this.updateJawabanAPI()
      if (this.currentNumber === this.totalQuestion.length) {
        this.currentNumber--
      } else {
        this.currentNumber++
      }

      this.$store.dispatch('cbt/UPDATE_JAWABAN_MURID_CBT_EXAM', {
        idCbtAttendance: this.dataQuestionCBTStudent.cbt_attendances[0].id,
        jawaban: this.jawabanAPI,
      })
    },
    submitAnswer() {
      if (!this.isTeacher) {
        this.loadingSubmit = true
        this.$store.dispatch('cbt/UPDATE_JAWABAN_MURID_CBT_EXAM', {
          idCbtAttendance: this.dataQuestionCBTStudent.cbt_attendances[0].id,
          jawaban: this.jawabanAPI,
        })
          .then(_ => {
            this.$store.dispatch('cbt/SUBMIT_ANSWER', {
              idCbtAttendance: this.dataQuestionCBTStudent.cbt_attendances[0].id,
            })
              .then(isSuccess => {
                if (isSuccess) {
                  setTimeout(() => {
                    this.$store.commit('cbt/SET_STATE', {
                      isOnExam: false,
                    })
                    this.loadingSubmit = false
                    this.$notification.success({
                      message: 'Success',
                      description:
                        'Your answer has been submitted. Good work!',
                    })
                    this.$router.push({ name: 'Cbt Student' })
                    // localStorage.removeItem('duration')
                    localStorage.setItem('isOnExam', false)
                  }, 2000)
                } else {
                  this.loadingSubmit = false
                  this.modalSubmit = false
                  this.$notification.error({
                    message: 'Error',
                    description:
                      'Internal server error. Please check your connection.',
                  })
                }
              })
          })
      } else {
        // console.log('OK SUBMIT GURU')
      }
    },
    updateJawabanAPI() {
      const arr = []
      for (let i = 0; i < this.totalQuestion.length; i++) {
        const question = this.totalQuestion[i]
        if (question.jawaban) {
          arr.push({
            id_soal: question.id,
            tipe_soal: question.tipeSoal,
            jawaban: question.jawaban,
          })
        }
      }

      this.jawabanAPI = arr
    },
  },
  created() {
    // console.log(this.isTeacher)
    if (!this.isTeacher) {
      this.$store.dispatch('cbt/FETCH_STUDENT_CBT_QUESTION', {
        idCBT: this.$route.params.id,
        idMurid: this.user.id,
      })
        .then(_ => {
          if (this.dataQuestionCBTStudent.cbt_attendances[0].jawaban && this.dataQuestionCBTStudent.cbt_attendances[0].jawaban.length) {
            const arrJawaban = this.dataQuestionCBTStudent.cbt_attendances[0].jawaban
            this.jawabanAPI = JSON.parse(arrJawaban)
          }
          this.totalQuestion = this.dataQuestionCBTStudent.cbt_soals.map((soal, i) => {
            let jawaban = null
            if (this.jawabanAPI.length) {
              this.jawabanAPI.forEach(answer => {
                if (answer.id_soal === soal.id) {
                  jawaban = answer.jawaban
                }
              })
            }
            return {
              id: soal.id,
              no: i + 1,
              soal: soal.soal,
              tipeSoal: soal.tipe_soal,
              pilihanJawaban: typeof soal.jawaban === 'string' ? JSON.parse(soal.jawaban) : soal.jawaban,
              status: jawaban ? 'answered' : 'undone',
              isNotSure: false,
              jawaban: jawaban,
            }
          })
          this.addStats()
          this.countdownTimer()
          // if (!parseInt(localStorage.duration)) {
          //   localStorage.setItem('duration', (this.duration.hours() * 3600) + (this.duration.minutes() * 60) + (this.duration.seconds()))
          // }
        })
    } else {
      this.totalQuestion = this.questions.map((soal, i) => {
        return {
          id: soal.id,
          no: i + 1,
          soal: soal.soal,
          tipeSoal: soal.tipe_soal,
          pilihanJawaban: typeof soal.jawaban === 'string' ? JSON.parse(soal.jawaban) : soal.jawaban,
          // status: jawaban ? 'answered' : 'undone',
          // isNotSure: false,
          // jawaban: jawaban,
        }
      })
      // console.log(this.totalQuestion)
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    dataQuestionCBTStudent() {
      return this.$store.state.cbt.dataQuestionCBTStudent
    },
    // totalQuestion() {
    //   if (this.dataQuestionCBTStudent.cbt_soals) {
    //     const arrSoal = this.dataQuestionCBTStudent.cbt_soals.map((soal, i) => {
    //       let jawaban = null
    //       if (this.jawabanAPI.length) {
    //         this.jawabanAPI.forEach(answer => {
    //           if (answer.id_soal === soal.id) {
    //             jawaban = answer.jawaban
    //           }
    //         })
    //       }
    //       return {
    //         id: soal.id,
    //         no: i + 1,
    //         soal: soal.soal,
    //         tipeSoal: soal.tipe_soal,
    //         pilihanJawaban: typeof soal.jawaban === 'string' ? JSON.parse(soal.jawaban) : soal.jawaban,
    //         status: jawaban ? 'answered' : 'undone',
    //         isNotSure: false,
    //         jawaban: jawaban,
    //       }
    //     })
    //     return arrSoal
    //   } else {
    //     return []
    //   }
    // },
  },
  watch: {
    currentNumber(newVal, oldVal) {
      if (!this.isTeacher) {
        if (this.totalQuestion[oldVal - 1].tipeSoal === 'Essay' && newVal !== oldVal) {
          this.$store.dispatch('cbt/UPDATE_JAWABAN_MURID_CBT_EXAM', {
            idCbtAttendance: this.dataQuestionCBTStudent.cbt_attendances[0].id,
            jawaban: this.jawabanAPI,
          })
            .then(isSuccess => {
              if (isSuccess) {
                this.$notification.success({
                  message: 'Updated',
                  description:
                    'Your answer has been updated',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description:
                    'Internal server error. Please check your connection.',
                })
              }
            })
        }
      }
    },
  },
}
</script>
<style lang="scss">
.examCBT {
  padding: 2% 4%;
  .loadingAnswer {
    position: relative;
    background-color: #2060ff;
    border: 1px solid #000;
    width: 50px;
    height: 50px;
    z-index: 1;
  }
  .countdownTimer {
    width: 13vw;
    height: 120px;
    background-color: white;
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 99999;
    box-shadow: 0px 5px 15px #6d807b80;
    display: flex;
    flex-direction: column;
    padding: 2%;
    align-items: center;
    h4 {
      color: #041930;
      font-size: 1.3rem;
    }
    h2 {
      margin: 0;
      color: #ff0000;
    }
  }
  .statusQuestion {
    .cardOverall {
      min-height: 154px;
      width: 90%;
      box-shadow: 0px 0px 10px #0419301a;
      border-radius: 6px;
      .centeringBody {
        display: inline-flex;
        align-items: flex-end;
        gap: 2%;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;

        .scoreStatus {
          font-size: 2.1rem;
          font-weight: 700;
        }

        .additionalText {
          font-size: 1.4rem;
          color: #041930;
          margin-bottom: 2%;
        }
      }
      .ant-card-head-title {
        font-weight: 700;
        font-size: 1.2rem;
        text-align: center;
      }
      .ant-card-body {
        height: 100%;
        padding: 24px;
      }
    }
  }
  .descStartExam {
    h2 {
      margin-top: 0 !important;
    }
  }
  .allExamNumber {
    display: flex;
    flex-wrap: wrap;
    .examNumber {
      display: flex;
      justify-content: center;
      min-width: 60px;
      min-height: 50px;

      button {
        min-width: 3rem;
      }
    }
  }

  .soalCBT {
    margin: 3% 0;
    min-height: 25vh;
  }

  .buttonCBT {
    .ant-btn-primary[disabled] {
      background-color: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
    }
    .notSureBtn:disabled {
      background-color: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}
</style>
